var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"custom-header-title float-left pr-1 mb-0"},[_vm._v(" Create New Service Request ")])])],1)],1)],1),_c('validation-observer',{ref:"serviceRequestCreateForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-card',{attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"primary-button-with-background mr-3",attrs:{"type":"button","variant":"flat-primary"}},[_c('feather-icon',{attrs:{"icon":"GridIcon","size":"20"}})],1),_c('h3',{staticClass:"align-middle mr-auto"},[_vm._v(" Service Requests Details ")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type*","label-for":"type","label-cols-md":"3","state":(errors.length > 0) ? false : null}},[_c('v-select',{attrs:{"id":"type","label":"name","placeholder":"Select an option","options":_vm.workFlowOptions,"reduce":function (name) { return name._id; },"selectable":function (option) { return !option.hasOwnProperty('category'); },"clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var category = ref.category;
var name = ref.name;
return [(category)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(category)+" ")]):_vm._e(),_vm._v(" "+_vm._s(name)+" ")]}}],null,true),model:{value:(_vm.workFlow),callback:function ($$v) {_vm.workFlow=$$v},expression:"workFlow"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.workFlowValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.workFlowError)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Date","vid":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:{ 'is-invalid': errors.length },attrs:{"label":"Date*","label-cols-md":"3","label-for":"date"}},[_c('b-input-group',[_c('flat-pickr',{staticClass:"form-control flat-pickr-group",attrs:{"id":"date","placeholder":"Select a Date","config":_vm.flatPickrConfigWithoutTime},on:{"input":_vm.checkForAssigneeSchedule},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"CalendarIcon","data-toggle":"","size":"18"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.dateValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.dateError)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Time","vid":"time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:{ 'is-invalid': errors.length },attrs:{"label":"Time*","label-cols-md":"3","label-for":"time"}},[_c('b-input-group',[_c('flat-pickr',{staticClass:"form-control flat-pickr-group",attrs:{"id":"time","placeholder":"Select a Time","config":_vm.flatPickrConfigTime},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"ClockIcon","data-toggle":"","size":"18"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.timeValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.timeError)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Location*","label-cols-md":"3","label-for":"location"}},[_c('validation-provider',{attrs:{"name":"Location","vid":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"location","placeholder":"e.g. Block A","state":(errors.length > 0) ? false : null,"name":"remarks"},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.locationValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.locationError)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Related File No.","label-cols-md":"3","label-for":"fileNo"}},[_c('validation-provider',{attrs:{"name":"Related File No.","vid":"fileNo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fileNo","placeholder":"Related File No.","state":(errors.length > 0) ? false : null,"name":"fileNo"},model:{value:(_vm.fileNo),callback:function ($$v) {_vm.fileNo=$$v},expression:"fileNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Remarks","label-cols-md":"3","label-for":"remarks"}},[_c('validation-provider',{attrs:{"name":"Remarks","vid":"remarks","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"remarks","placeholder":"Remarks","state":(errors.length > 0) ? false : null,"name":"remarks"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"serviceForm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Related Service Form*","label-for":"serviceForm","label-cols-md":"3","state":(errors.length > 0) ? false : null}},[_c('v-select',{attrs:{"id":"serviceForm","label":"stringID","placeholder":"e.g. SF000048","options":_vm.serviceFormOptions,"reduce":function (stringID) { return stringID._id; },"clearable":false},model:{value:(_vm.serviceForm),callback:function ($$v) {_vm.serviceForm=$$v},expression:"serviceForm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.serviceFormValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.serviceFormError)+" ")]):_vm._e()],1)]}}])})],1)],1)],1),(_vm.customFields.length > 0)?_c('b-card',{attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"primary-button-with-background mr-3",attrs:{"type":"button","variant":"flat-primary"}},[_c('feather-icon',{attrs:{"icon":"GridIcon","size":"20"}})],1),_c('h3',{staticClass:"align-middle mr-auto"},[_vm._v(" Service Descriptions ")])]},proxy:true}],null,false,4252817680)},_vm._l((_vm.customFields),function(opt,key){return _c('b-row',{key:key},[_c('b-col',{attrs:{"cols":"12"}},[(opt.type == 'title-and-text')?_c('div',[_c('p',{staticClass:"text-bold-black"},[_vm._v(" "+_vm._s(opt.label)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.serviceDescription[key].text)+" ")])]):_vm._e(),(opt.type == 'short-answer')?_c('b-form-group',{attrs:{"label":opt.label,"label-for":'event-custom-field-' + key,"label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":opt.label,"vid":'event-custom-field-' + key,"rules":opt.required == 'yes' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'event-custom-field-' + key,"placeholder":opt.label,"state":(errors.length > 0) ? false : null},model:{value:(_vm.serviceDescription[key].value),callback:function ($$v) {_vm.$set(_vm.serviceDescription[key], "value", $$v)},expression:"serviceDescription[key].value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(opt.type == 'long-answer')?_c('b-form-group',{attrs:{"label":opt.label,"label-for":'event-custom-field-' + key,"label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":opt.label,"vid":'event-custom-field-' + key,"rules":opt.required == 'yes' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":'event-custom-field-' + key,"trim":"","placeholder":opt.label,"state":(errors.length > 0) ? false : null},model:{value:(_vm.serviceDescription[key].value),callback:function ($$v) {_vm.$set(_vm.serviceDescription[key], "value", $$v)},expression:"serviceDescription[key].value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(opt.type == 'increment')?_c('b-form-group',{attrs:{"label":opt.label,"label-for":'event-custom-field-' + key,"label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":opt.label,"vid":'event-custom-field-' + key,"rules":opt.required == 'yes' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-spinbutton',{staticClass:"spin-button-sd",attrs:{"id":'event-custom-field-' + key,"inline":"","state":(errors.length > 0) ? false : null,"step":opt.extraOptions[2].value,"min":opt.extraOptions[0].value,"max":opt.extraOptions[1].value},model:{value:(_vm.serviceDescription[key].value),callback:function ($$v) {_vm.$set(_vm.serviceDescription[key], "value", $$v)},expression:"serviceDescription[key].value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(opt.type == 'multi-select' && opt.multiSelectType == 'multiple')?_c('b-form-group',{attrs:{"label-cols-md":"2","label":opt.label,"label-for":'event-custom-field-' + key}},[_c('validation-provider',{attrs:{"name":opt.label,"rules":opt.required == 'yes' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{staticClass:"spaceBetweenRadio display-block-checkbox",attrs:{"id":'event-custom-field-' + key,"buttons":"","button-variant":"outline-primary"},model:{value:(_vm.serviceDescription[key].value),callback:function ($$v) {_vm.$set(_vm.serviceDescription[key], "value", $$v)},expression:"serviceDescription[key].value"}},_vm._l((opt.extraOptions),function(optn,kindex){return _c('b-form-checkbox',{key:kindex,staticClass:"mb-50 customCheckbox",attrs:{"value":optn.key}},[(_vm.serviceDescription[key].value.includes(optn.key))?_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon","size":"16"}}):_vm._e(),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(optn.key))])],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(opt.type == 'multi-select' && opt.multiSelectType == 'single')?_c('b-form-group',{attrs:{"label":opt.label,"label-for":'event-custom-field-' + key,"label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":opt.label,"rules":opt.required == 'yes' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{staticClass:"spaceBetweenRadio display-block-checkbox",attrs:{"id":'event-custom-field-' + key,"buttons":"","button-variant":"outline-primary"},on:{"input":function($event){return _vm.makeCheckboxToRadio(key)}},model:{value:(_vm.serviceDescription[key].value),callback:function ($$v) {_vm.$set(_vm.serviceDescription[key], "value", $$v)},expression:"serviceDescription[key].value"}},_vm._l((opt.extraOptions),function(optn,kindex){return _c('b-form-checkbox',{key:kindex,staticClass:"mb-50 customCheckbox",attrs:{"value":optn.key}},[(_vm.serviceDescription[key].value.includes(optn.key))?_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon","size":"16"}}):_vm._e(),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(optn.key))])],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(opt.type == 'single-select')?_c('b-form-group',{attrs:{"label-cols-md":"2","label":opt.label,"label-for":'event-custom-field-' + key}},[_c('validation-provider',{attrs:{"name":opt.label,"rules":opt.required == 'yes' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{staticClass:"spaceBetweenRadio display-block-checkbox",attrs:{"id":'event-custom-field-' + key,"buttons":"","button-variant":"outline-primary"},on:{"input":function($event){return _vm.makeCheckboxToRadio(key)}},model:{value:(_vm.serviceDescription[key].value),callback:function ($$v) {_vm.$set(_vm.serviceDescription[key], "value", $$v)},expression:"serviceDescription[key].value"}},_vm._l((_vm.requiredOptions),function(optn,kindex){return _c('b-form-checkbox',{key:kindex,staticClass:"mb-50 customCheckbox",attrs:{"value":optn.value}},[(_vm.serviceDescription[key].value.includes(optn.value))?_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon","size":"16"}}):_vm._e(),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(optn.text))])],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(opt.type == 'dropdown')?_c('validation-provider',{attrs:{"name":opt.label,"vid":'event-custom-field-' + key,"rules":opt.required == 'yes' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-md":"2","label":opt.label,"label-for":'event-custom-field-' + key,"state":(errors.length > 0) ? false : null}},[_c('v-select',{attrs:{"id":'event-custom-field-' + key,"label":"value","placeholder":"Select From List","options":opt.extraOptions,"reduce":function (value) { return value.key; },"clearable":true},model:{value:(_vm.serviceDescription[key].value),callback:function ($$v) {_vm.$set(_vm.serviceDescription[key], "value", $$v)},expression:"serviceDescription[key].value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),((!opt.parent && _vm.customFields[key + 1] && _vm.customFields[key + 1].parent != opt.id) || (opt.parent && _vm.customFields[key + 1] && _vm.customFields[key + 1].parent != opt.parent))?_c('hr'):_vm._e()],1)],1)}),1):_vm._e(),_c('b-card',{ref:"payment_details",attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"primary-button-with-background mr-3",attrs:{"type":"button","variant":"flat-primary"}},[_c('feather-icon',{attrs:{"icon":"GridIcon","size":"20"}})],1),_c('h3',{staticClass:"align-middle mr-auto"},[_vm._v(" Payment Details ")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Payment Status","vid":"paymentStatus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Payment Status*","label-for":"h-bookings-paymentStatus","label-cols-md":"4","state":(errors.length > 0 || _vm.paymentStatusValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-bookings-paymentStatus","label":"title","placeholder":"Select from list","options":_vm.paymentStatusOptions,"reduce":function (title) { return title.code; },"clearable":false},on:{"input":function($event){_vm.paymentStatusValidation == true ? _vm.paymentStatusValidation = false : null}},model:{value:(_vm.paymentStatus),callback:function ($$v) {_vm.paymentStatus=$$v},expression:"paymentStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.paymentStatusValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.paymentStatusError)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}}),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Payment Remarks","label-for":"h-booking-paymentRemarks","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Payment Remarks","vid":"paymentRemarks","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"event-paymentRemarks","placeholder":"Payment Remarks","rows":"3","name":"paymentRemarks","readonly":!_vm.canViewThisAction('update', 'ServiceForm') && !_vm.canViewThisAction('update-payment', 'ServiceForm')},on:{"input":function($event){_vm.paymentRemarksValidation == true ? _vm.paymentRemarksValidation = false : null}},model:{value:(_vm.paymentRemarks),callback:function ($$v) {_vm.paymentRemarks=$$v},expression:"paymentRemarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.paymentRemarksValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.paymentRemarksError)+" ")]):_vm._e()]}}])})],1)],1)],1)],1),_c('b-card',{attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"primary-button-with-background mr-3",attrs:{"type":"button","variant":"flat-primary"}},[_c('feather-icon',{attrs:{"icon":"GridIcon","size":"20"}})],1),_c('h3',{staticClass:"align-middle mr-auto"},[_vm._v(" Others ")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"md-9"}},[_c('validation-provider',{attrs:{"name":"Assignees","vid":"assignees","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Assignee","label-for":"h-user-roles","label-cols-md":"2","state":(errors.length > 0 || _vm.assigneesValidation) ? false : null}},[_c('b-form-tags',{attrs:{"size":"lg","add-on-change":"","no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var disabled = ref.disabled;
var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-1 user__lists"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":tag,"disabled":disabled,"variant":"danger"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(" "+_vm._s(_vm.resolveUserName(tag))+" ")])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" Choose a User.... ")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers),_vm._l((_vm.userOptions),function(user,ind){return _c('option',{key:ind,domProps:{"value":user._id}},[_vm._v(" "+_vm._s(user.name)+" ")])}),0)]}}],null,true),model:{value:(_vm.assignees),callback:function ($$v) {_vm.assignees=$$v},expression:"assignees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.assigneesValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.assigneesError)+" ")]):_vm._e()],1)]}}])}),(_vm.assignmentError)?_c('div',{staticClass:"service_assign_error"},[_c('h5',[_vm._v("Warning")]),_c('div',[_c('p',[_vm._v("Assingee(s) may not be available for the the request. Are you sure you want to proceed?")]),_c('ul',_vm._l((_vm.assignmentErrorOptions),function(error,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(error.date)+" - "+_vm._s(error.name)+" ("+_vm._s(error.leave)+") ")])}),0)])]):_vm._e()],1),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status*","label-for":"h-user-status","label-cols-md":"3","state":(errors.length > 0 || _vm.statusValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-user-status","label":"title","options":_vm.option,"reduce":function (title) { return title.code; },"clearable":false},on:{"input":function($event){_vm.statusValidation == true ? _vm.statusValidation = false : null}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.statusValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.statusError)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"md-6"}})],1)],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary","to":{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' } }}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save Request")])],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }